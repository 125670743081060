export interface DocumentPreviewEnabledSearchCriteria {

    all: boolean,
    docHdrId: boolean,
    billingType: boolean,
    chargeTypeList: boolean,
    billingCycleCode: boolean,
    billToCompList: boolean,
    vesselName: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    opsDateFrom: boolean,
    opsDateTo: boolean,  
    opsDateRange: boolean,  
    genDateFrom: boolean,
    genDateTo: boolean,  
    genDateRange: boolean,  
    genBy: boolean

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_DOCUMENT_PREVIEW_SEARCH_CRITERIA: DocumentPreviewEnabledSearchCriteria = {

    all:true,
    docHdrId: true,
    billingType: true,
    chargeTypeList: true,
    billingCycleCode: true,
    billToCompList: true,
    vesselName: true,
    vesselCode: true,
    voyageCode: true,
    opsDateFrom: true,
    opsDateTo: true,  
    opsDateRange: true,  
    genDateFrom: true,
    genDateTo: true,  
    genDateRange: true,  
    genBy: true
}