export interface DocumentPreviewFirstPrintCriteria {    
    docHdrIds: number [],
    finalizedBy: string,
    invPrinter: string,
    invFileLoc: string,
    suppPrinter: string,
    suppFileLoc: string,

    [key: string]: string[] | string | boolean | number | number[] | null | undefined
}

export const EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA : DocumentPreviewFirstPrintCriteria ={
    invPrinter: "",
    invFileLoc: "",
    suppPrinter: "",
    suppFileLoc: "",
    finalizedBy: "",
    docHdrIds: []
}