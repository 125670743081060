import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DocumentPreviewConstant } from "./DocumentPreviewConstant";

const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.DetailTable;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_DOCUMENT_PREVIEW_DETAIL_COL_DEF: any[] = [
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TARIFF_TYPE,
        field: 'tariffType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: false,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TARIFF_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TARIFF_CODE_DESC,
        field: 'tariffCodeDescTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.DTL_DESC1,
        field: 'dtlDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.COMMON_AC_PERCENTAGE,
        field: 'commonAcPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGE_AMOUNT,
        field: 'amountTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 280,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGING_QTY,
        field: 'chargingQtyTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType:"Double"
        //dataType: "date"
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.UNIT_PRICE,
        field: 'unitPrice',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 320,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.CHARGING_UOM,
        field: 'chargeQtyUom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TAX,
        field: 'vatPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.TAX_AMOUNT,
        field: 'adjAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 320,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.ORIG_INVOICE_NUMBER,
        field: 'origInvoiceNumberTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: DOCUMENT_PREVIEW_CONSTANT.ORIG_INVOICE_ISSUSE_DATE,
        field: 'origInvoiceIssueDateTemp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
        //dataType: "string"
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
