import { EMPTY_DOCUMENT_PREVIEW_DETAIL_MODEL } from 'presentation/model/DocumentPreview/DocumentPreviewDetailModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: DocumentPreviewDetailProvider,
    useTracked: useDocumentPreviewDetailTracked
} = createContainer(() => useState(EMPTY_DOCUMENT_PREVIEW_DETAIL_MODEL), {concurrentMode: true});
export { DocumentPreviewDetailProvider, useDocumentPreviewDetailTracked };

