import { FC, useCallback, useEffect, useRef, useState } from "react";
import { HPHButton, IconButton, OverflowingMenuWSubMenu, OverflowingSubMenu } from "veronica-ui-component/dist/component/core";

export interface MenuItem {
  command?: () => void,
  label: string
  icon?: string
  items?: MenuItem[],
  buttonSize?: string,
  buttonTheme?:string
}

export const OverflowMenuButton: FC<{ 
    icon?: string, 
    toolTipText?: string, 
    disabled?: boolean, 
    navigationList: OverflowingSubMenu[], 
    buttonLabel?:string,
    buttonSize?:'Standard' | 'Small',
    buttonTheme?:'Primary' | 'Secondary' | 'Alert'
    position?: 'right' | 'left' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
    className?: string,
    }> = ({ 
        icon, 
        toolTipText="Preference", 
        disabled=false, 
        navigationList, 
        buttonLabel,
        buttonSize='Small',
        buttonTheme='Secondary',
        position='bottomLeft',
        className=''
      }): any => {
    
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const showMenuClick = useCallback(() => {
      setShowMenu(!showMenu);
  }, [showMenu]);

  const commandWrapper = useCallback((originalCommand: (() => void) | undefined): (() => void) => {
      return () => {
        if (originalCommand) {
          originalCommand();
        }
        showMenuClick();
      };
  }, [showMenuClick]);

  const commandWrapperRef = useRef(commandWrapper);
    
  useEffect(() => {
    commandWrapperRef.current = commandWrapper;

    const updateCommandsRecursively = (items: OverflowingSubMenu[]): void => {
      items.forEach((item) => {
        if (item.command) {
          item.command = commandWrapperRef.current(item.command);
        }
        if (item.submenu) {
          updateCommandsRecursively(item.submenu);
        }
      });
    };

    updateCommandsRecursively(navigationList);
  }, [commandWrapper, navigationList]);

    return <div className={className}> {/*className="icon-with-menu" */}
        <div onMouseLeave={() => setShowMenu(false)} >
        {icon && <IconButton fileName={icon} disabled={disabled} toolTipPlacement={"right"} tooltipDisable={false} size="medium" onClick={showMenuClick} toolTipText={toolTipText} />}
        {buttonLabel && <HPHButton label={buttonLabel} disabled={disabled} size={buttonSize} onClick={showMenuClick} theme={buttonTheme}/>}
        {showMenu && <OverflowingMenuWSubMenu menuItem={navigationList} height={"auto"} width={"auto"} 
            position={position} showArrow className={"title-overflowMenu"} />}
        </div>
    </div>;
};
