import { EMPTY_DOCUMENT_PREVIEW_MODEL } from 'presentation/model/DocumentPreview/DocumentPreviewModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: DocumentPreviewProvider,
    useTracked: useDocumentPreviewTracked
} = createContainer(() => useState(EMPTY_DOCUMENT_PREVIEW_MODEL), {concurrentMode: true});
export { DocumentPreviewProvider, useDocumentPreviewTracked };

