export const DocumentPreviewConstant = {

    Title: {
        INVOICE: "Draft Invoice",
        PREVIEWREF_NO: "Preview Ref. No.",
        DOC_TYPE: "Doc. Type",
        BILL_TO_COMPANY: "Bill To Company",
        CUSTOMER_CODE: "Customer Code",
        CO_VSL_VOY: 'Co/Vsl/Voy',
        ETD: 'ETD',
        OPS_DATE: 'Ops Date',
        FINALIZE_BUT: "Finalize & 1st Print",
        REJECT_BUT: "Withdraw",
        INVOICE_PREVIEW_BUT: "Invoice Preview",
        WAIT_APPROVAL_BUT: "Pending Approval",
        DISAPPROVED_BUT: "Rejected",
        NOT_APPROVED_BUT: "Ready for Finalize",
        APPLY_BUT: "Apply",
        APPROVE_BUT: "Approve",
        DISAPPROVE_BUT: "Reject",
        PREVIEW_DOC: "Preview Document",
        DOWNLOAD_DOC: "Download Document",
        PREVIEW_SUP: "Preview Supporting",
        DOWNLOAD_SUP: "Download Supporting",
        UPDATE: "Update",
    },
    Search: {
        SEARCH_CRITERIA: "Search Criteria",
        BILLING_TYPE: "Billing Type",
        CHARGE_TYPE: "Charge Type",
        BILLING_CYCLE_CODE: "Billing Cycle Code",
        BILL_TO_COMPANY: "Bill-To Company",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        VESSEL_NAME: "Vessel Name",
        OPS_DATE_FROM: "Ops Date(From)",
        OPS_DATE_TO: "Ops Date(To)",
        GEN_DATE_FROM: "Gen.Date(From)",
        GEN_DATE_TO: "Gen.Date(To)",
        GEN_BY: "Gen. By",
        OPS_DATE_RANGE: "Ops Date Range",
        GEN_DATE_RANGE: "Gen. Date Range",
    },
    
    Table: {
        PREVIEWREF_NO: "Preview Ref. No.",
        DOC_TYPE: "Doc. Type",
        HANDLING_TML: "Handling Tml.",
        SERVICE_CODE: "Service Code",
        BILL_TO_COMPANY: "Bill To Company",
        CUSTOMER_CODE: "Customer Code",
        CUSTOMER_NAME1: "Customer Name 1",
        CUSTOMER_NAME2: "Customer Name 2",
        OPS_DATE: "Ops. Date",
        CHARGE_TYPE: "Charge Type",
        CONSORTIUM_CODE: "Consortium Code",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        VESSEL_NAME: "Vessel Name",
        DEP_DATE_TIME: "Dep Date/Time",
        ERROR_MESSAGE: "Error Message",
        GENERATION_BY: "Generated by",
        GENERATION_DATE: "Generation Date",
        CONTRACT_CURRENCY: "Contract Currency",
        BILLING_TYPE: "Billing Type",
        BILLING_CYCLE_CODE: "Billing Cycle Code",
        PO_NO: "P.O. No.",
        APPROVAL_STATUS: "Approval Status",
        PAYMENT_DUE_DATE: "Payment Due Date",
        ADJ_TYPE: "Adj. Type",
        CONVERTED_CURRENCY_CODE: "Payment Currency",
        LOCAL_CURRENCY_CODE: "Local Currency",
        ETD: "ETD",
        TAX_PERCENTAGE: "Tax Percentage",
        CREATED_BY: "Created By",
        CREATED_DATE: "Created Date Time",
    },

    DetailTable: {
        TARIFF_TYPE: "Tar Type",
        TARIFF_CODE: "Tar Code",
        TARIFF_CODE_DESC: "Tar Desc.",
        DTL_DESC1: "Detail Desc1",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        COMMON_AC_PERCENTAGE: "Common Account %",
        CHARGE_AMOUNT: "Amount in Contract Currency",
        CHARGING_QTY: "Charging Qty",
        UNIT_PRICE: "Unit Price in Contract Currency",
        CHARGING_UOM: "Charging UOM",
        TAX: "Tax %",
        TAX_AMOUNT: "Tax Amount in Contract Currency",
        ORIG_INVOICE_NUMBER: "Original invoice number",
        ORIG_INVOICE_ISSUSE_DATE: "Original invoice issue date",
    },
    Detail: {
        INVOCIE_ITEM_LIST: "Draft Invoice Item List",
        MOVE_TO_ITEM_LIST: "Move To Item List",
        MOVE_TO: "Move To",
        NEW_INVOICE: "New invoice",
        EXISTING_DRAFT_INVOICE: "Existing draft invoice",
    }
}