import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { convertDateToDateForComponent, convertDateToDateTimeForComponent } from "presentation/utils/timeUtil";

export const DocumentPreviewDetailHeaderPanel = () => {
    
    const [documentPreviewState] = useDocumentPreviewTracked();   
    const { currentSelectedData } = documentPreviewState;

    return <> 
        <div className="headerInfo">
            <div className="sevenColumns">
                <div className={"item"}>
                    <div className={"label"}>
                        {DocumentPreviewConstant.Title.PREVIEWREF_NO}
                    </div>
                    <div className={"value"}>
                        {currentSelectedData?.previewRefNo as number}
                    </div>
                </div>
                <div className={"item"}>
                    <div className={"label"}>
                        {DocumentPreviewConstant.Title.DOC_TYPE}
                    </div>
                    <div className={"value"}>
                        {currentSelectedData?.docType as string}
                    </div>
                </div>

                <div className={"item"}>
                    <div className={"label"}>
                        {DocumentPreviewConstant.Title.BILL_TO_COMPANY}
                    </div>
                    <div className={"value"}>
                        {currentSelectedData?.billToCompany as string}
                    </div>
                </div>

                <div className={"item"}>
                    <div className={"label"}>
                        {DocumentPreviewConstant.Title.CUSTOMER_CODE}
                    </div>
                    <div className={"value"}>
                        {currentSelectedData?.customerCode as string}
                    </div>
                </div>

                {(currentSelectedData?.consortiumCode || currentSelectedData?.vesselCode || currentSelectedData?.voyageCode) 
                && <div className={"item"}>
                        <div className={"label"}>
                            {DocumentPreviewConstant.Title.CO_VSL_VOY}
                        </div>
                        <div className={"value"}>
                            {`${currentSelectedData?.consortiumCode}/
                            ${currentSelectedData?.vesselCode}/
                            ${currentSelectedData?.voyageCode}`}
                        </div>
                    </div>
                }                
                {currentSelectedData?.etd && <div className={"item"}>
                    <div className={"label"}>
                        {DocumentPreviewConstant.Title.ETD}
                    </div>
                    <div className={"value"}>
                        {convertDateToDateTimeForComponent(currentSelectedData?.etd)}
                    </div>
                </div>}
                <div className={"item"}>
                    <div className={"label"}>
                        {DocumentPreviewConstant.Title.OPS_DATE}
                    </div>
                    <div className={"value"}>
                        {convertDateToDateForComponent(currentSelectedData?.opsDate)}
                    </div>
                </div>
            </div>
        </div>
    </>
}