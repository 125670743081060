export interface DocumentPreviewSearchCriteria{
    docHdrId?: number | null,
    billingType?: string | null,
    chargeTypeList?: string[] | null,
    billingCycleCode?: string | null,
    billToCompList?: string[] | null,
    vesselName?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,  
    genDateFrom?: Date | null,
    genDateTo?: Date | null,  
    genBy?: string | null,
    isSearchSelectDocumentPreviewList?: boolean,
    approvalStatus?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA : DocumentPreviewSearchCriteria = {

    docHdrId: null,
    billingType: null,
    chargeTypeList: [],
    billingCycleCode: null,
    billToCompList: [],
    vesselName: null,
    vesselCode: null,
    voyageCode: null,
    opsDateFrom: null,
    opsDateTo: null,  
    genDateFrom: null,
    genDateTo: null,  
    genBy: null,   
    approvalStatus: null,   
}