import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentPreviewDetailProvider } from "presentation/store/DocumentPreview/DocumentPreviewDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import DocumentPreviewDetailMaintenance from "presentation/view/section/DocumentPreview/Detail/DocumentPreviewDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const DocumentPreviewDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.DOCUMENT_PREVIEW_DETAIL}>
        <MessageBarWrapper>
            <DocumentPreviewDetailProvider>
                <GridStyles/>
                <DocumentPreviewDetailMaintenance/>
            </DocumentPreviewDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default DocumentPreviewDetailContMain;