import { DocumentPreviewDetailEntity } from "domain/entity/DocumentPreview/DocumentPreviewDetailEntity";
import { DocumentPreviewEntity } from "domain/entity/DocumentPreview/DocumentPreviewEntity";
import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { BaseViewChangeSate } from "../BaseViewChangeState";

export interface DocumentPreviewChangeState extends BaseViewChangeSate {
}

export interface DocumentPreviewDetailModel {
    isLoading: boolean,
    allFormState?: {[x:string]:string},  
    documentPreviewDtlList: DocumentPreviewDetailEntity[],
    selectedDtlRows: DocumentPreviewDetailEntity[],
    currentDocumentPreview: DocumentPreviewEntity,
    moveToItemList: DocumentPreviewEntity[],
    selectMoveToItem: DocumentPreviewEntity[],
    subMenuItemArray: MenuItem[],
    isShowMoveToPanel: boolean,
    
}

export const EMPTY_DOCUMENT_PREVIEW_DETAIL_MODEL: DocumentPreviewDetailModel = {
    isLoading: false,
    allFormState: {},
    documentPreviewDtlList: [],
    selectedDtlRows: [],
    currentDocumentPreview: {},
    moveToItemList: [],
    isShowMoveToPanel: false,
    selectMoveToItem: [],
    subMenuItemArray: [],
}