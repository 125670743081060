import { EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { useDocumentPreviewDetailVM } from "presentation/hook/DocumentPreview/useDocumentPreviewDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentPreviewDetailTracked } from "presentation/store/DocumentPreview/DocumentPreviewDetailProvider";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { memo, useCallback, useMemo } from "react";
import { HPHBreadcrumb } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const DocumentPreviewDetailTitleBar:React.FC = () => {
    const documentPreviewDetailVM = useDocumentPreviewDetailVM();
    const [documentPreviewDetailState] = useDocumentPreviewDetailTracked();
    const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.Detail;
    const messageBarVM = useMessageBarVM();
    const [anainfoState] = useANAInfoTracked();
    const {allowUpdate} = anainfoState;
    
    const handleClose = () => {
        documentPreviewDetailVM.onCloseDtlScreen();
      }

    const handleNewInvoice = useCallback(async () => {       
        
        await documentPreviewDetailVM.addOrApplyDocDataForMove([],documentPreviewDetailState.selectedDtlRows).then(async (data) => {    
            if(data){
                messageBarVM.showSuccess("Move Success.");
              }else{
                messageBarVM.showError("Move Failed.");
              } 
            let tmmId = documentPreviewDetailState.currentDocumentPreview.id as number;
            let newSearchCriteria = {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA} 
                newSearchCriteria = { ...newSearchCriteria, 
                    docHdrId:tmmId, 
                };
            await documentPreviewDetailVM.searchInvItemDtl(newSearchCriteria).then((data) => {  
                documentPreviewDetailVM.onHideLoading();
            }).catch(error => {            
                documentPreviewDetailVM.onHideLoading();
            });
        }).catch(error => {            
            documentPreviewDetailVM.onHideLoading();
        }).finally(() => {
            documentPreviewDetailVM.onHideLoading();
        })
    }, [documentPreviewDetailState.currentDocumentPreview.id, documentPreviewDetailState.selectedDtlRows, documentPreviewDetailVM, messageBarVM])
    
    const handleExistingDraftInvoice = useCallback(async () => {       
        
        documentPreviewDetailVM.onShowLoading();
        await documentPreviewDetailVM.searchDataForMove(documentPreviewDetailState.selectedDtlRows).then((data) => {            
            documentPreviewDetailVM.onHideLoading();
        }).catch(error => {            
            documentPreviewDetailVM.onHideLoading();
        }).finally(() => {
            documentPreviewDetailVM.onHideLoading();
        })
    }, [documentPreviewDetailState.selectedDtlRows, documentPreviewDetailVM])    

    const memoMoveToMenu = useMemo(() => {       

        return [
            {
                title: DOCUMENT_PREVIEW_CONSTANT.NEW_INVOICE,
                disabled: false,
                command: () => {
                    handleNewInvoice();
                }
            },
            {
                title: DOCUMENT_PREVIEW_CONSTANT.EXISTING_DRAFT_INVOICE,
                disabled: false,
                command: () => {
                    handleExistingDraftInvoice();
                }
            },
        ]
    }, [DOCUMENT_PREVIEW_CONSTANT.EXISTING_DRAFT_INVOICE, DOCUMENT_PREVIEW_CONSTANT.NEW_INVOICE, handleExistingDraftInvoice, handleNewInvoice])

    const isDisable = () => {
        if (!documentPreviewDetailState.isShowMoveToPanel && documentPreviewDetailState.selectedDtlRows.length > 0) return false;
        if (documentPreviewDetailState.selectedDtlRows.length > 0 && documentPreviewDetailState.selectedDtlRows[0].recalByBillToCompany === 'Y') return false;

        return true;
    }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderWithBackButton callback={handleClose}>
        <Breadcrumb>
        <HPHBreadcrumb breadcrumbData={[{title: ChargeConstant.Charge.CHARGE},{ title: DocumentPreviewConstant.Title.INVOICE }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        </HeaderWithBackButton>
        {/* <HeaderTitle>{DOCUMENT_PREVIEW_CONSTANT.INVOCIE_ITEM_LIST}</HeaderTitle> */}
        <StyledAction className="tm-animated-wrapper">
            {(allowUpdate) && <OverflowMenuButton disabled={isDisable()} buttonLabel={DOCUMENT_PREVIEW_CONSTANT.MOVE_TO} navigationList={memoMoveToMenu} />
            }
            {/* {(allowUpdate) && <div className="add-seperator"/>} */}
            {/* <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={handleClose} /> */}

        </StyledAction>
    </Sidebarheader>
}

export default memo(DocumentPreviewDetailTitleBar);