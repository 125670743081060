import { DocumentPreviewRepoImpl } from "domain/repository/DocumentPreview/DocumentPreviewRepoImpl";
import { useDocumentPreviewDetailTracked } from "presentation/store/DocumentPreview/DocumentPreviewDetailProvider";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { DocumentPreviewDetailVM } from "presentation/viewModel/DocumentPreview/DocumentPreviewDetailVM";
import { useMemo } from "react";

export const useDocumentPreviewDetailVM = () => {
    const [, setDocumentPreviewDetailState] = useDocumentPreviewDetailTracked();
    const [, setDocumentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewDetailVM = useMemo(() =>
        DocumentPreviewDetailVM({
            dispatch: [setDocumentPreviewDetailState],
            hdrDispatch: [setDocumentPreviewState],
            documentPreviewRepo: DocumentPreviewRepoImpl(),
        }), [setDocumentPreviewDetailState, setDocumentPreviewState])

    return documentPreviewDetailVM
}

