import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DocumentPreviewConstant } from "./DocumentPreviewConstant";

const MOVE_TO_CONSTANT = DocumentPreviewConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_DOCUMENT_PREVIEW_MOVE_TO_COL_DEF: any[] = [
    {
        headerName: MOVE_TO_CONSTANT.PREVIEWREF_NO,
        field: 'id',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: false,
        filter: false,
        width: 200,
    },
    {
        headerName: MOVE_TO_CONSTANT.DOC_TYPE,
        field: 'docType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: MOVE_TO_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: MOVE_TO_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: MOVE_TO_CONSTANT.CUSTOMER_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: MOVE_TO_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: MOVE_TO_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MOVE_TO_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MOVE_TO_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MOVE_TO_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: MOVE_TO_CONSTANT.PAYMENT_DUE_DATE,
        field: 'paymentDueDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
        dataType: "date"
    },
    {
        headerName: MOVE_TO_CONSTANT.TAX_PERCENTAGE,
        field: 'adjPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: MOVE_TO_CONSTANT.CONTRACT_CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: MOVE_TO_CONSTANT.CONVERTED_CURRENCY_CODE,
        field: 'convertedCurrencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MOVE_TO_CONSTANT.LOCAL_CURRENCY_CODE,
        field: 'localCurrencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MOVE_TO_CONSTANT.CREATED_BY,
        field: 'createdBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,
    },
    {
        headerName: MOVE_TO_CONSTANT.CREATED_DATE,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "date"
    },


].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
