import { SelectionChangedEvent } from "ag-grid-community";
import { DocumentPreviewDetailEntity } from "domain/entity/DocumentPreview/DocumentPreviewDetailEntity";
import { INITIAL_DOCUMENT_PREVIEW_DETAIL_COL_DEF, transferRowData } from "presentation/constant/DocumentPreview/DocumentPreviewDetailColumnDefinition";
import { useDocumentPreviewDetailVM } from "presentation/hook/DocumentPreview/useDocumentPreviewDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentPreviewDetailTracked } from "presentation/store/DocumentPreview/DocumentPreviewDetailProvider";
import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, RightClickMenu } from "veronica-ui-component/dist/component/core";

const DocumentPreviewDetailTablePanel:React.FC = () => {
    const documentPreviewDetailVM = useDocumentPreviewDetailVM();
    const [documentPreviewDetailState] = useDocumentPreviewDetailTracked();
    const gridRef: any = useRef(null);
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const rightClickRef: any = createRef();
    const {subMenuItemArray,selectedDtlRows} = documentPreviewDetailState;
    const messageBarVM = useMessageBarVM();    
    const [anainfoState] = useANAInfoTracked();
    const {allowUpdate} = anainfoState;
    
    useEffect(() => {
        const columnDefs = (INITIAL_DOCUMENT_PREVIEW_DETAIL_COL_DEF.slice());
            // fix DM-9503 by Simikka
            if (!documentPreviewDetailState.selectedDtlRows || documentPreviewDetailState.selectedDtlRows.length <= 0) {
                gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
                if (!onTableSelectionClicked) {
                    gridRef.current?.gridRef.current.api?.deselectAll();
                }
            }
            
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);        
        const selectedRows = e.api.getSelectedRows();

        documentPreviewDetailVM.updateSelectedDtlRows(selectedRows);
      }, [documentPreviewDetailVM])

    useEffect(() => {
            if (onTableSelectionClicked) return;
            
            gridRef?.current?.gridRef.current.api?.deselectAll();
        }, [onTableSelectionClicked])

    useEffect(() => {
        const handleRightClickMenu = (event:any) => {            
            if(selectedDtlRows.length < 1 || !allowUpdate){                
                return; 
            }

            const allDatas = documentPreviewDetailState.documentPreviewDtlList;            
            const selDtlTierCountMap: Map<string, number> = new Map();
            const selDtlTariffCountMap: Map<string, number> = new Map();
            
            const allDtlTierCountMap: Map<string, number> = new Map();
            const allDtlTariffCountMap: Map<string, number> = new Map();

            selectedDtlRows.forEach(selRow =>{                
                const tierKey = getDtlTierKey(selRow);
                if(selDtlTierCountMap.has(tierKey)){                    
                    selDtlTierCountMap.set(tierKey, (selDtlTierCountMap.get(tierKey) as number) + 1)
                } else {
                    selDtlTierCountMap.set(tierKey, 1)
                }
                
                const tariffKey = getDtlTariffKey(selRow);
                if(selDtlTariffCountMap.has(tariffKey)){                    
                    selDtlTariffCountMap.set(tariffKey, (selDtlTariffCountMap.get(tariffKey) as number) + 1)
                } else {
                    selDtlTariffCountMap.set(tariffKey, 1)
                }
            })
            
            allDatas.forEach(row =>{
                const tierKey = getDtlTierKey(row);
                if (row.docAdjType === null && row.tierNo !== null) {
                    if (allDtlTierCountMap.has(tierKey)) {
                        allDtlTierCountMap.set(tierKey, (allDtlTierCountMap.get(tierKey) as number) + 1);
                    } else {
                        allDtlTierCountMap.set(tierKey, 1);
                    }
                }

                const tariffKey = getDtlTariffKey(row);
                if(allDtlTariffCountMap.has(tariffKey)){                    
                    allDtlTariffCountMap.set(tariffKey, (allDtlTariffCountMap.get(tariffKey) as number) + 1)
                } else {
                    allDtlTariffCountMap.set(tariffKey, 1)
                }
            })
            
            Object.keys(selDtlTierCountMap).forEach(key => {
                if (allDtlTierCountMap.get(key)
                     && selDtlTierCountMap.get(key) !== allDtlTierCountMap.get(key)) {
                    messageBarVM.showWarining("Please choose all the tiers together to move.");
                    return;    			
			    }
                
              });
            
            const selKeyCount = selDtlTariffCountMap.size;
            const selKey = selDtlTariffCountMap.keys().next().value as string;

            if((selKeyCount && selKeyCount > 1)){
                messageBarVM.showWarining("Please choose one tariff to move.");
                return; 
            }

            if(selDtlTariffCountMap.get(selKey) !== allDtlTariffCountMap.get(selKey)){
                messageBarVM.showWarining("Please choose all the rows with same tariff together to move.");
                return; 
            }

            event.preventDefault();
            rightClickRef.current.show(event);
        };

        const getDtlTierKey = (row: DocumentPreviewDetailEntity) => {
            let tierKey;
            if (row.getDocAdjType === null) { 
                tierKey = (row.tariffType? row.tariffType: "null") + "_" +  
                        (row.tariffCode? row.tariffCode: "null") +
                        (row.tariffCodeDesc? row.tariffCodeDesc:"null") + "_" + 
                        (row.chargeType? row.chargeType:"null") + "_" + 
                        (row.subChargeType? row.subChargeType:"null") + "_" + 
                        (row.dtlDesc1? row.dtlDesc1:"null")+ "_" + 
                        (row.dtlDesc2? row.dtlDesc2:"null")+ "_" + 
                        (row.docType? row.docType:"null")+ "_" + 
                        (row.adjType? row.adjType:"null")+ "_" + 
                        (row.chargeQtyUom? row.chargeQtyUom:"null");
            } else {
                tierKey = (row.tariffType? row.tariffType:"null") + "_" + 
                        (row.tariffCode? row.tariffCode:"null") + "_" + 
                        (row.tariffCodeDesc? row.tariffCodeDesc:"null") + "_" + 
                        (row.chargeType? row.chargeType:"null") + "_" + 
                        (row.subChargeType? row.subChargeType:"null") + "_" + 
                        (row.docAdjType? row.docAdjType:"null");
            } 
            return tierKey; 
        }

        const getDtlTariffKey = (row: DocumentPreviewDetailEntity) => {
            let tariffKey;
            
            tariffKey = (row.tariffType? row.tariffType: "null") + "_" + 
                        (row.tariffCode? row.tariffCode: "null") + "_" + 
                        (row.prioritySequence? row.prioritySequence:"null");

            return tariffKey; 
        }

        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
          document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [documentPreviewDetailState.documentPreviewDtlList, messageBarVM, rightClickRef, selectedDtlRows, allowUpdate])
   
    const moveItemList: MenuItem[] = useMemo(() => {
        const menuItemArray: MenuItem[] = [];

        menuItemArray.push({
            label: 'Move After',
            items:subMenuItemArray
        })
        return menuItemArray;
    },[subMenuItemArray]);

    const memoChangeKeyDataTable = useMemo(() => {

        return (<div id="myDiv">
            {(allowUpdate) && <RightClickMenu disabled={selectedDtlRows.length===0} element={rightClickRef} items={moveItemList} />}
        <HPHTable
            id='change-key-data-table'
            isNewColumnSetting={true}
            columns={INITIAL_DOCUMENT_PREVIEW_DETAIL_COL_DEF.slice()}
            data={transferRowData(documentPreviewDetailState.documentPreviewDtlList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={true}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 175px)"      
            ref={gridRef}
        />
        </div>
        );
    },[documentPreviewDetailState.documentPreviewDtlList, handleSelectionChange, moveItemList, rightClickRef, selectedDtlRows.length, allowUpdate])

    return <><TableWrapper>
        {memoChangeKeyDataTable}</TableWrapper></>;
}

export default memo(DocumentPreviewDetailTablePanel);
