import { EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { useDocumentPreviewDetailVM } from "presentation/hook/DocumentPreview/useDocumentPreviewDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useDocumentPreviewDetailTracked } from "presentation/store/DocumentPreview/DocumentPreviewDetailProvider";
import { useCallback } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import DocumentPreviewDraftInvoiceListTable from "./DocumentPreviewDraftInvoiceListTable";


export const DocumentPreviewDraftInvoiceListPanel = () => {

  const [documentPreviewDetailState] = useDocumentPreviewDetailTracked();
  const documentPreviewDetailVM = useDocumentPreviewDetailVM();
  const messageBarVM = useMessageBarVM();

  const handleCancel = useCallback(async() => {
    documentPreviewDetailVM.onCancelMoveTo();
  }, [documentPreviewDetailVM]);

  const handleMove = useCallback(async() => {
    documentPreviewDetailVM.onShowLoading();
    await documentPreviewDetailVM.addOrApplyDocDataForMove(documentPreviewDetailState.selectMoveToItem,documentPreviewDetailState.selectedDtlRows).then(async (data) => {     
      if(!data){
        messageBarVM.showError("Move Failed.");
      }else{
        messageBarVM.showSuccess("Move Success.");
      }
      let tmmId = documentPreviewDetailState.currentDocumentPreview.id as number;
      let newSearchCriteria = {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA} 
          newSearchCriteria = { ...newSearchCriteria, 
              docHdrId:tmmId, 
          };

      await documentPreviewDetailVM.searchInvItemDtl(newSearchCriteria).then((data) => {  
        documentPreviewDetailVM.onHideLoading();
      }).catch(error => {            
          documentPreviewDetailVM.onHideLoading();
      });      
      documentPreviewDetailVM.onHideLoading();
  }).catch(error => {            
      documentPreviewDetailVM.onHideLoading();
  }).finally(() => {
      documentPreviewDetailVM.onHideLoading();
  })
  }, [documentPreviewDetailState.currentDocumentPreview.id, documentPreviewDetailState.selectMoveToItem, documentPreviewDetailState.selectedDtlRows, documentPreviewDetailVM, messageBarVM]);

  const isDisable = () => {
    if (documentPreviewDetailState.selectMoveToItem.length === 1) return false;
    return true;
  }

  return <>
      {(documentPreviewDetailState.isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        <div className="main-comp-wrapper">
          <DocumentPreviewDraftInvoiceListTable/>
          
            {
              <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center", justifyContent: "flex-end"}}>
                <StyledAction className="tm-animated-wrapper">
                    <HPHButton disabled={false} label={"Cancel"} size={'Small'} theme={'Secondary'} onClick={handleCancel} />
                    <HPHButton disabled={isDisable()} label={"Move"} size={'Small'} theme={'Primary'} onClick={handleMove} />
                </StyledAction>
              </Sidebarheader>
            }
        </div>
  </>
}
